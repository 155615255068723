
import { defineComponent } from "vue";
import KPICard from "./KPICard.vue";
import Skeleton from "primevue/skeleton";
import { mapState, mapGetters, mapActions } from "vuex";
import store from "@/store";

export default defineComponent({
  components: {
    KPICard,
    Skeleton
  },
  props: {
    customerId: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      selectedStatCard: "/orders",
      invoicesLoading: false,
      billsLoading: false,
      ordersLoading: false,
      items: [
        { label: "Invoices", to: "/invoices" },
        { label: "Tickets ", to: "/tickets" },
        { label: "Sales ", to: "/sales" },
      ],
    };
  },
  computed: {
    ...mapState(["customer", "env"]),
    ...mapGetters({
      ordersMonthlyData: "sales/getSoMonthlyData",
      invoicesMonthlyData: "invoice/getMonthlyData",
      ticketsMonthlyData: "billing/getMonthlyData",
      getWebAccess: "session/getWebAccess",
    }),
    userHasTicketAccess(): boolean{
      return this.getWebAccess("TICKET.E");
    }
  },
  methods: {
    ...mapActions({
      saveInnerTabItem: "customerInquiry/saveInnerTabItem",
    }),
    handleCardClick(to: string) {
      switch(to){
        case "/customers/ar":
          this.activeIndex = 12;
          this.$emit('activeIndexChanged', this.activeIndex);
          this.saveInnerTabItem("/customers/ar");
          break;
        case "/customers/billing":
          this.activeIndex = 13;
          this.$emit('activeIndexChanged', this.activeIndex);
          this.saveInnerTabItem("/customers/billing");
          break;
        case "/customers/sales/orders":
          this.activeIndex = 10;
          this.$emit('activeIndexChanged', this.activeIndex);
          this.saveInnerTabItem("/customers/sales/orders");
          break;
      }
    },  
    async fetchMonthlyData() {
      if(this.customerId){
        let date = new Date();
        date.setMonth(date.getMonth() - 11);
        
          if (this.env.modules.includes("Sales") || this.env.modules.length == 0) {
            store.dispatch("sales/fetchSoMonthlyData", {
              month: date.getMonth() + 1,
              year: date.getFullYear(),
              cust: this.customerId,
            })
            this.ordersLoading = true;
          }
          if (this.env.modules.includes("Invoices") || this.env.modules.length == 0) {
           store.dispatch("invoice/fetchMonthlyData", {
              month: date.getMonth() + 1,
              year: date.getFullYear(),
              cust: this.customerId,
            })
            this.invoicesLoading = true;
          }
          if (this.env.modules.includes("Tickets") || this.env.modules.length == 0) {
            store.dispatch("billing/fetchMonthlyData", {
              month: date.getMonth() + 1,
              year: date.getFullYear(),
              cust: this.customerId,
            })
            this.billsLoading = true;
          }
      }
    },
  },
  watch: {
    customerId() {
      this.fetchMonthlyData();
    },
  },
});
